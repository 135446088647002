const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require( 'nordic/i18n');
const CarouselFree = require('@andes/carousel-free');
const { CarouselFreeSlide } = require('@andes/carousel-free');
const RecommendationsContainer = require('./recommendations-container');
const WeekDeal = require('./view-more/week-deal');
const Action = require('../action');
const withTracker = require('../with-tracker');
const Item = require('../item/mobile');
const withTrackerOnPrint = require('../with-tracker-on-print');

const viewMoreSlides = {
  'week-deal': WeekDeal,
};

const RecommendationsMobile = (props) => {
  const {
    deviceType,
    items,
    lowEnd,
    lazyLoad,
    metadata,
    isServerSide,
    id,
    view_more_element_action: viewMoreElementAction,
    view_more_action: viewMoreAction,
  } = props;

  const imgSize = deviceType === 'mobile' ? '160' : '224';

  const extendedItems = items.map((item, i) => ({
    ...item,
    lowEnd,
    lazyLoad,
    metadata,
    isFirstPage: i < 6, // Amount of item images that we want them to act as a first page
    imgSize,
    deviceType,
  }));

  const shouldRenderViewMoreSlide = !(isServerSide && !lowEnd);
  const ViewMoreSlide = viewMoreSlides[id];

  if (ViewMoreSlide && viewMoreElementAction && shouldRenderViewMoreSlide) {
    extendedItems.push({
      Component: ViewMoreSlide,
      lazyLoad,
      view_more_action: {
        ...viewMoreAction,
        label: deviceType === 'mobile' ? 'Ver más' : deviceType.label,
      },
      ...viewMoreElementAction,
    });
  }

  if(extendedItems.length < 1) return null;
  return (
    <RecommendationsContainer {...props}>
      <CarouselFree
        spacing={12}
        slidesPerView={2}
        srLabel="carousel"
        arrows={false}
      >
        {extendedItems.map((item) => (
          <CarouselFreeSlide key={item.id}>
            <Item {...item} />
          </CarouselFreeSlide>
        ))}
      </CarouselFree>
    </RecommendationsContainer>
  );
};

RecommendationsMobile.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes),
  view_more_element_action: PropTypes.shape(Action.propTypes),
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  lowEnd: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  isServerSide: PropTypes.bool,
};

RecommendationsMobile.defaultProps = {
  view_more_action: null,
  view_more_element_action: null,
};

module.exports = withTracker(withTrackerOnPrint(injectI18n(RecommendationsMobile)));

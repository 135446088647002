const React = require('react');
const PropTypes = require('prop-types');
const { Image } = require('nordic/image');

const ViewHistorySlide = ({ target, label, picture_url: pictureUrl }) => (
  <a href={target} className="view-history-slide">
    <div className="content ui-item ui-item__wrapper squared-item__wrapper">
      <Image src={pictureUrl} alt={label} />
      <span>{label}</span>
    </div>
  </a>
);

ViewHistorySlide.propTypes = {
  target: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  picture_url: PropTypes.string.isRequired,
};

module.exports = ViewHistorySlide;

const PropTypes = require('prop-types');
const React = require('react');
const CarouselFree = require('@andes/carousel-free');
const { CarouselFreeSlide } = require('@andes/carousel-free');
const OfficialStores = require('./official-stores');
const OfficialStoresSlide = require('./official-stores-slide');

const OfficialStoresMobile = (props) => {
  const { lazyLoad, lowEnd, items } = props;
  const slides = items.map(element => ({
    ...element,
    id: element.permalink,
    lazyLoad,
    lowEnd,
  }));
  if(slides.length > 1 ) return null;
  return (
    <OfficialStores {...props}>
      <CarouselFree
        strictBoundaries={false}
      >
        {slides.map(item => (
          <CarouselFreeSlide key={item.id}>
            <OfficialStoresSlide {...item} />
          </CarouselFreeSlide>
        ))}
      </CarouselFree>
    </OfficialStores>
  );
};

OfficialStoresMobile.propTypes = {
  items: PropTypes.array.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

module.exports = OfficialStoresMobile;

/**
* Styles
*/
require('../../shared/components/pages/home/home.mobile.scss');

/**
* Module dependencies
*/
const startApp = require('../start-app');
const Home = require('../../shared/components/pages/home/home.mobile');

startApp(Home);

const React = require('react');
const PropTypes = require('prop-types');
const { Image } = require('nordic/image');
const Action = require('../../action');

// wdvms = week deal view more slide

const WeekDealViewMoreSlide = ({ target, picture_url: pictureUrl, label, view_more_action: viewMoreAction }) => (
  <div className="ui-item__wrapper wdvms__wrapper">
    <a className="ui-card ui-item wdvms" href={target}>
      <div className="wdvms-content">
        <div className="wdvms-picture">
          <Image src={pictureUrl} />
        </div>
        <div className="wdvms-label">{label}</div>
        <div className="wdvms-view-more">{viewMoreAction.label}</div>
      </div>
    </a>
  </div>
);

WeekDealViewMoreSlide.propTypes = {
  label: PropTypes.string.isRequired,
  picture_url: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes).isRequired,
};

module.exports = WeekDealViewMoreSlide;

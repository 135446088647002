const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');
const CityCard = require('./city-card');
const Section = require('../../../commons/section');
const withTracker = require('../../../commons/with-tracker');

class CitiesMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      watchMore: false,
    };
    this.handleShowMore = this.handleShowMore.bind(this);
  }

  handleShowMore(e) {
    e.preventDefault();
    this.setState((state) => ({ watchMore: !state.watchMore }));
  }

  render() {
    const { type, title, items } = this.props;
    const { watchMore } = this.state;

    return (
      <Section type={type}>
        <div className="row container">
          <div className="section-header">
            <h2>{title}</h2>
          </div>
          <div className="wrapper">
            {items.map((item, index) => {
              const showCard = watchMore || index < 6;
              return (
                <div key={item.id} className={showCard ? 'city-wrapper' : 'hidden-city'}>
                  <CityCard {...item} hideImage />
                </div>
              );
            })}
          </div>
          <div className="watch-more-wrapper">
            <button className="watch-more-wrapper__button" onClick={this.handleShowMore}>
              <span className="watch-more-wrapper__text">
                {watchMore ? 'Ver menos ciudades' : 'Ver más ciudades'}
              </span>
            </button>
          </div>
        </div>
      </Section>
    );
  }
}

CitiesMobile.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
};

module.exports = withTracker(injectI18n(CitiesMobile));

const React = require('react');
const PropTypes = require('prop-types');
const key = require('../../../../../lib/weak-key');
const Section = require('../../../commons/section');
const Chevron = require('./chevron');

class MostSearched extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementActive: '',
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick(selected) {
    const { elementActive } = this.state;

    if (elementActive === selected) {
      this.setState({ elementActive: '' });
    } else {
      this.setState({ elementActive: selected });
    }
  };

  render() {
    const { title, type, categories } = this.props;
    const { elementActive } = this.state;
    const elements = categories.map((category) => {
      const locations = category.locations.map((location) => <li key={key(location)}><a className="panel_links" href={location.url}>{location.name}</a></li>);
      const isActive = elementActive === category.title;
      return (
        <div className='panel_wrapper' key={key(category)}>
          <button
            onClick={() => { this.onClick(category.title); }}
            className={`${isActive ? 'active' : ''}`}
          >{category.title} <Chevron />
          </button>
          <div className={`panel ${isActive ? 'open' : ''}`}>
            <ul>
              {locations}
            </ul>
          </div>
        </div>
      );
    });

    return (
      <Section type={type}>
        <div className="row container">
          <div className="section-header">
            <h2>{title}</h2>
          </div>
          <div className="most-searched_container">
            {elements}
          </div>
        </div>
      </Section>
    );
  }
}

MostSearched.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = MostSearched;

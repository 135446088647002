const React = require('react');
const PropTypes = require('prop-types');
const CarouselFree = require('@andes/carousel-free');
const { CarouselFreeSlide } = require('@andes/carousel-free');
const Advice = require('./index');
const AdviceItem = require('../../../commons/advice-item/index');
const Utils = require('../../../../utils');

const AdviceMobileContainer = ({ items, title, className = 'advice-mobile', maxTitleLength = 72 }) => {
  const formattedElements = Utils.ellipsisTrim(items, 'title', maxTitleLength);

  return (
    <Advice className={className} title={title}>
      <CarouselFree>
        {formattedElements.length > 0 && formattedElements.map(item => (
          <CarouselFreeSlide key={item.id}>
            <AdviceItem {...item} />
          </CarouselFreeSlide>
        ))}
      </CarouselFree>
    </Advice>
  );
};

AdviceMobileContainer.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  maxTitleLength: PropTypes.number,
};

AdviceMobileContainer.defaultProps = {
  className: 'advice-mobile',
  maxTitleLength: 72,
};

module.exports = AdviceMobileContainer;

const React = require('react');
const PropTypes = require('prop-types');
const Mobile = require('vis-faceted-search/lib/Mobile');
const SavedSearchesMobile = require('vis-faceted-search/lib/SavedSearchesMobile');
const Section = require('../../../commons/section');
const { findById } = require('./utils/find-by-attribute');
const { buildSearchUrl, buildMapSearchUrl } = require('./utils/build-url');
const { getSearchUrlParams } = require('./utils/get-search-url-params');
const { saveCurrentSearch, disableSavedSearchesTutorial } = require('./utils/last-search');
const {
  COMPONENT_IDS: { FACETED_SEARCH },
  DEFAULT_MAP_URL,
  DEFAULT_URL,
} = require('./utils/constants');
const { trackSearchEvent, trackSearchMapEvent } = require('./utils/events');
const restClient = require('../../../../../client/services/rest-client');

class SearchBar extends React.Component {
  constructor(props) {

    super(props);
    this.createHandleSearch = this.createHandleSearch.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.handleMapSearch = this.createHandleSearch(buildMapSearchUrl, DEFAULT_MAP_URL, state => trackSearchMapEvent(state));
    this.handleSearch = this.createHandleSearch(buildSearchUrl, DEFAULT_URL, state => trackSearchEvent(state));
  }

  getInitialState() {
    const { currentSearch = {} } = this.props;
    return currentSearch;
  }

  createHandleSearch(buildUrl, defaultUrl, trackEvent) {
    const { components } = this.props;
    const { items = [] } = findById(components, FACETED_SEARCH) || {};

    const handleSearch = (_event, searchState) => {
      const params = getSearchUrlParams(searchState, items);

      restClient
        .get('/search/url', { params })
        .then((response) => {
          trackEvent(searchState);

          if (response?.data?.url) {
            saveCurrentSearch(searchState);
            window.location = buildUrl(response.data.url);
          } else {
            window.location = defaultUrl;
          }
        })
        .catch(() => {
          window.location = defaultUrl;
        });
    };

    return handleSearch;
  }

  render() {
    const { type, components } = this.props;
    const facetedSearch = findById(components, FACETED_SEARCH);
    const { savedSearches, items, hideSavedSearchesTutorial } = facetedSearch;

    if (savedSearches && !hideSavedSearchesTutorial) {
      disableSavedSearchesTutorial();
    }

    return (
      <Section type={type}>
        <div className="row container">
          <div className="search-bar__box">
            {facetedSearch && (
              <Mobile
                initialState={this.getInitialState()}
                renderConfig={items}
                callToAction="Buscar"
                onAction={this.handleSearch}
                secondaryAction={{ label: 'Buscar en Mapa', onAction: this.handleMapSearch }}
              >
                {savedSearches && (
                  <SavedSearchesMobile renderConfig={savedSearches} showTooltip={!hideSavedSearchesTutorial} />
                )}
              </Mobile>
            )}
          </div>
        </div>
      </Section>
    );
  }
}

// TODO: missing proptypes
SearchBar.propTypes = {
  components: PropTypes.instanceOf(Object).isRequired,
};

module.exports = SearchBar;

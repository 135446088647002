const React = require('react');

const Chevron = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="7" height="11" viewBox="0 0 7 11">
    <defs>
      <path d="M5.47 6.03l1.06-1.06 5.53 5.53-5.53 5.53-1.06-1.06 4.47-4.47z" />
    </defs>
    <g fill="none" fillRule="evenodd" style={{ 'mix-blend-mode': 'multiply' }} transform="translate(-5 -5)">
      <use fill="#3483FA" xlinkHref="#a" />
    </g>
  </svg>
);

module.exports = Chevron;

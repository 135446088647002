const React = require('react');
const PropTypes = require('prop-types');
const CarouselFree = require('@andes/carousel-free');
const { CarouselFreeSlide } = require('@andes/carousel-free');
const Action = require('../../../commons/action');
const Item = require('../../../commons/item');
const withTracker = require('../../../commons/with-tracker');
const Navigation = require('./navigation');
const NavigationSlide = require('./navigation-slide-mobile');
const ViewHistorySlide = require('./view-history-slide');

function NavigationMobile(props) {
  const {
    deviceType,
    items,
    lazyLoad,
    metadata,
    view_more_element_action: viewMoreElementAction,
  } = props;
  const imgSize = deviceType === 'mobile' ? '120' : '156';
  const slides = items.map(element => ({
    ...element,
    lazyLoad,
    metadata,
    imgSize,
  }));

  if (viewMoreElementAction) {
    slides.push({
      Component: ViewHistorySlide,
      lazyLoad,
      ...viewMoreElementAction,
    });
  }
  if(slides.length < 1 ) return null;
  return (
    <Navigation {...props}>
      <CarouselFree
        srLabel="carousel"
        lazyLoad
      >
        {slides.map(item => (
          <CarouselFreeSlide key={item.id}>
            <NavigationSlide {...item} />
          </CarouselFreeSlide>
          ))}
      </CarouselFree>
    </Navigation>
  );
}

NavigationMobile.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape(NavigationMobile.propTypes)).isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  view_more_element_action: PropTypes.shape(Action.propTypes),
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

NavigationMobile.defaultProps = {
  view_more_element_action: null,
};

module.exports = withTracker(NavigationMobile);

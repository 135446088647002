const React = require('react');
const Home = require('./home');
const AdvertisingBillboard = require('./advertising-billboard');
const Cities = require('./cities/mobile');
const Advice = require('./advice/mobile');
const Discovery = require('./discovery');
const Exhibitor = require('./exhibitor');
const Navigation = require('./navigation/mobile');
const Recommendations = require('../../commons/recommendations/mobile');
const SearchBar = require('./search-bar/mobile');
const PublishWithUs = require('./publish-with-us');
const MostSearched = require('./most-searched/mobile');
const OfficialStoresMobile = require('./official-stores/mobile');

const components = {
  'advertising-billboard': AdvertisingBillboard,
  cities: Cities,
  advice: Advice,
  'official-stores': OfficialStoresMobile,
  discovery: Discovery,
  exhibitor: Exhibitor,
  navigation: Navigation,
  recommendations: Recommendations,
  'search-bar': SearchBar,
  'publish-with-us': PublishWithUs,
  'most-searched': MostSearched,
};

function HomeMobile(props) {
  return (
    <div>
      <Home {...props} availableComponents={components} />
    </div>
  );
}
module.exports = HomeMobile;

const React = require('react');
const PropTypes = require('prop-types');
const Item = require('../../../commons/item');
const withTracker = require('../../../commons/with-tracker');

function NavigationSlide(props) {
  return (
    <div className="navigation-slide">
      <Item {...props} />
    </div>
  );
}

NavigationSlide.propTypes = {
  id: PropTypes.string.isRequired,
};

module.exports = withTracker(NavigationSlide);
